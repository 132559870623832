(function ($) {
  "use strict";

  /*-------------------------------------*
     # logout
     --------------------------------------*/
     $('.logout').on('click',function(){
        event.preventDefault();
        document.getElementById('logout-form').submit();
    })

  /*----------------------------------
  # header sticky
  -----------------------------------*/

  var activeSticky = $("#active-sticky"),
    winDow = $(window);
  winDow.on("scroll", function () {
    var scroll = $(window).scrollTop(),
      isSticky = activeSticky;
    if (scroll < 1) {
      isSticky.removeClass("is-sticky");
    } else {
      isSticky.addClass("is-sticky");
    }
  });

  /*----------------------------------
  # Off Canvas Menu
  -----------------------------------*/

  var $offcanvasNav = $("#offcanvasNav a");
  $offcanvasNav.on("click", function () {
    var link = $(this);
    var closestUl = link.closest("ul");
    var activeLinks = closestUl.find(".active");
    var closestLi = link.closest("li");
    var linkStatus = closestLi.hasClass("active");
    var count = 0;

    closestUl.find("ul").slideUp(function () {
      if (++count == closestUl.find("ul").length)
        activeLinks.removeClass("active");
    });

    if (!linkStatus) {
      closestLi.children("ul").slideDown();
      closestLi.addClass("active");
    }
  });

  /*-----------------------------------
  # brand-carousel
  ------------------------------ */

  var brandCarousel = new Swiper(".brand-carousel .swiper-container", {
    loop: true,
    speed: 800,
    autoplay: {
      delay: 2000,
    },
    slidesPerView: 4,
    spaceBetween: 0,
    pagination: false,
    navigation: false,
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 2,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 2,
      },
      // when window width is >= 640px
      768: {
        slidesPerView: 3,
      },

      // when window width is >= 640px
      992: {
        slidesPerView: 4,
      },
    },
  });

  /*-----------------------------------
  # brand-carousel
  ------------------------------ */

  var testimonialCarousel = new Swiper(
    ".testimonial-carousel .swiper-container",
    {
      loop: false,
      speed: 1000,
      slidesPerView: 1,
      spaceBetween: 0,
      pagination: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    }
  );

  /*-----------------------------------
  # window load function
  ------------------------------ */
  var winDow = $(window);
  winDow.on("load", function () {
    /* Isotope Settings */
    var masonryGrid = $("#grid");
    var teamMasonryGrid = $("#tam-grid");

    // masonry grid
    if (masonryGrid.length) {
      masonryGrid.isotope({
        itemSelector: ".grid-item",
        // layout mode options
        layoutMode: "masonry",
      });
    }
    if (teamMasonryGrid.length) {
      teamMasonryGrid.isotope({
        itemSelector: ".team-grid-item",
        // layout mode options
        layoutMode: "masonry",
      });
    }
  });

  /*--------------------
  # counter active
  ---------------------*/

//   $(".counter").counterUp({
//     delay: 10,
//     time: 1000,
//   });

  /*----------------------------
  # parallax - start
  -------------------------------*/

  $(".scene").each(function () {
    new Parallax($(this)[0], {
      relativeInput: true,
    });
  });

  /*----------------------------
  #  Copy Right Year Update
  -------------------------------*/

  $("#currentYear").text(new Date().getFullYear());
  /*----------------------------
  #  scrollUp
  -------------------------------*/
  $.scrollUp({
    scrollName: "scrollUp",
    // Element ID
    scrollDistance: 400,
    // Distance from top/bottom before showing element (px)
    scrollFrom: "top",
    // 'top' or 'bottom'
    scrollSpeed: 200,
    // Speed back to top (ms)
    easingType: "linear",
    // Scroll to top easing (see http://easings.net/)
    animation: "fade",
    // Fade, slide, none
    animationSpeed: 300,
    // Animation speed (ms)
    scrollTrigger: false,
    // Set a custom triggering element. Can be an HTML string or jQuery object
    scrollTarget: false,
    // Set a custom target element for scrolling to. Can be element or number
    scrollText: '<i class="icofont-long-arrow-up"></i>',
    // Text for element, can contain HTML
    scrollTitle: false,
    // Set a custom <a> title if required.
    scrollImg: false,
    // Set true to use image
    activeOverlay: false,
    // Set CSS color to display scrollUp active point, e.g '#00FFFF'
    zIndex: 214, // Z-Index for the overlay
  });

      /*-----------------------------------------------------------------------
     * smooth scrolling to all frontend
     * full compatible across all browser solution - better than css property => scroll-behavior: smooth;
     * prevent hash
     -------------------------------------------------------------------------*/
       $("a").on('click', function(event) {

        // Make sure this.hash has a value before overriding default behavior
        // Also ensure this page contain that id

        if (this.hash !== "" && $(this.hash).length > 0) {

            // Prevent default anchor click behavior
            event.preventDefault();

            // Store hash
            var hash = this.hash;

            // Using jQuery's animate() method to add smooth page scroll
            //  $(selector).animate({params},speed,callback);
            // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
            $('html, body').animate({
                scrollTop: $(hash).offset().top - 100
            }, 1000, function() {

                // Below line add hash (#) to URL when done scrolling (default click behavior)
                // window.location.hash = hash;
            });

        } // End if

    });

})(jQuery);
